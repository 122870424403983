import React, { useState } from "react";
import styles from "./index.module.scss";
import { STATES, STATES_DATA } from "../../common/constants";
import { useSpring, animated, useSpringRef } from "react-spring";
import DropDown from "../../components/Dropdown";
import Popup from "reactjs-popup";
import icon from "../../assets/images/info.png";
import "reactjs-popup/dist/index.css";
import Header from "../../components/Header";
import { useNavigate } from "react-router";
import brandIcon from "../../assets/images/brandIcon.png";
import { isMobile } from "react-device-detect";
import { MobLinks } from "../Home";
import { signInWithEmailAndPassword } from "firebase/auth";
import ReactGA from "react-ga4";

function Login({ showLogin = true, setInProgress = () => {}, auth }) {
  const [state, setState] = useState("");
  const [store, setStore] = useState("");
  const [code, setCode] = useState("");
  const [activeField, setActiveField] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleState = (value) => {
    setState(value);
    setStore("");
  };
  const handleStore = (value) => {
    setStore(value);
  };

  const propsWrapper = useSpring({
    to: { opacity: showLogin ? 1 : 0 },
    from: { opacity: 0 },
    delay: 500,
  });

  const stylesIcon = useSpring({
    from: { y: 0, opacity: 0 },
    to: { y: -20, opacity: 1 },
    delay: 500,
  });

  const navigate = useNavigate();
  const onLogin = () => {
    // Hack to add TAS state
    let tempState = state;
    if (tempState === STATES.TAS) tempState = STATES.VIC;
    
    let webCode = `${tempState}_${store}_${code.toUpperCase()}`;
    // setInProgress(true);
    setIsLoading(true);
    
    console.log(webCode.toLocaleLowerCase() + "@event.com");

    // Validate Unique Code
    signInWithEmailAndPassword(
      auth,
      webCode.toLocaleLowerCase() + "@event.com",
      webCode
    )
      .then((confirmationResult) => {
        console.log("Logged on!");
        ReactGA.event("login", {
          state,
          store,
          code: code.toUpperCase(),
          webCode,
        });
        // setInProgress(false);
        setIsLoading(false);
        window.localStorage.setItem("sessionCode", webCode);
        navigate("sessions");
      })
      .catch((err) => {
        console.log(err);
        if (err.code === "auth/user-not-found") {
          setError("Please enter a valid code.");
        } else {
          setError(err.message);
        }
        // setInProgress(false);
        setIsLoading(false);
      });
  };

  if (!showLogin) {
    return null;
  }

  const contentStyle = { background: "#000" };
  const disabled =
    state === "" || store === "" || code === "" || code.length !== 4;
  return (
    <animated.div className={styles.loginUI} style={propsWrapper}>
      {!isMobile && <Header />}
      <div className={styles.form}>
        {isMobile && (
          <animated.div style={stylesIcon}>
            <img src={brandIcon} alt="santa" className={styles.brandIcon} />
          </animated.div>
        )}
        <h2>Login via Unique Code</h2>
        <DropDown
          values={STATES}
          onChange={handleState}
          selectedValue={state}
          placeholder="Select State"
          activeField={activeField}
          setActiveField={setActiveField}
          isOpen={isOpen && activeField === "Select State"}
          setIsOpen={setIsOpen}
        />

        <div style={{ marginBottom: 37 }} />
        <DropDown
          values={STATES_DATA[state] || {}}
          onChange={handleStore}
          selectedValue={store}
          placeholder="Select Store"
          activeField={activeField}
          setActiveField={setActiveField}
          isOpen={isOpen && activeField === "Select Store"}
          setIsOpen={setIsOpen}
        />
        <div style={{ marginBottom: 37 }} />
        <div className={styles.inputWrapper}>
          <input
            className={styles.inputStyle}
            value={code}
            onChange={(e) => setCode(e.target.value)}
            placeholder="Enter your unique code"
            maxLength={4}
            style={{
              textTransform: "uppercase",
              color: code.length == 4 ? "#000" : "#e40a2d",
              borderColor: code.length == 4 ? "#000" : "#e40a2d",
            }}
          />
          <div className={styles.infoIcon}>
            <Popup
              trigger={<img src={icon} alt="info" className={styles.info} />}
              {...contentStyle}
              position="bottom right"
            >
              <div className={styles.popupInfo}>
                The unique code can be found at the bottom right corner of the
                prints
              </div>
            </Popup>
          </div>
          <div style={{ marginBottom: 42 }} />
        </div>
        <button
          className={styles.enterBtn}
          style={{
            opacity: disabled ? 0.4 : 1,
            cursor: disabled ? "not-allowed" : "pointer",
          }}
          onClick={onLogin}
          disabled={disabled || isLoading}
        >
          Submit
        </button>
        <div className={styles.error}>{error}</div>
        {isMobile && <MobLinks />}
      </div>
    </animated.div>
  );
}

export default Login;
