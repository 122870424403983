import React, { useEffect, useState } from "react";
import styles from "./SessionCard.module.scss";
import { collection, query, where, getDocs, limit } from "firebase/firestore";

import { isEmpty } from "lodash";
import { useNavigate } from "react-router";
import { SERVER_URL, STATES_DATA } from "../../common/constants";

function SessionCard({ session, db }) {
  const {
    name,
    location,
    timestamp = {},
    userId,
    docId,
    sold: paid = false,
    unfinished = false,
  } = session;
  const [sessionImages, setImages] = useState([]);
  useEffect(() => {
    async function getImages() {
      const data = [];
      const q = query(
        collection(db, "images"),
        where("sessionId", "==", docId),
        limit(4)
      );
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        if (data.length < 3 && doc.data().type !== "jumbo")
          data.push(doc.data());
      });
      return data;
    }
    getImages().then((res) => {
      setImages(res);
    });
  }, [db, docId]);

  const navigation = useNavigate();
  if (isEmpty(session)) {
    return null;
  }
  const timestampDateTime = new Date(timestamp.toDate());
  const locationSplit = location.split("_");
  let locationStr = name;
  let state = locationSplit[0];
  let site = locationSplit[1];
  // Hack to add Tasmania
  if (site === "HOBA" || site === "LAUN") state = "TAS";
  if (locationSplit.length > 1 && STATES_DATA[locationSplit[0]])
    locationStr = STATES_DATA[state][site] + ", " + state;
  return (
    <div className={styles.sessionCard}>
      <h2>Your Session</h2>
      <div>
        <h3>{locationStr}</h3>
        <h4>
          {timestampDateTime.toDateString() +
            ", " +
            timestampDateTime.toLocaleTimeString()}
        </h4>
      </div>
      <div className={styles.imagesRow}>
        {sessionImages.map((image, key) => {
          return (
            <div
              key={key}
              style={{
                transform: `scale(${key === 1 ? 1 : 0.9})`,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <img
                src={paid ? image.url : image.watermarkUrl}
                className={styles.demoImg}
                alt={image.type}
              />
              <div className={styles.imgInfo}>{image.type}</div>
            </div>
          );
        })}
      </div>
      <div className={styles.actionBtns}>
        {!paid && (
          <form action={SERVER_URL + "/create-checkout-session"} method="POST">
            <input type="hidden" name="sessionId" value={docId} />
            <button type="submit" className={styles.buyBtn}>
              Buy Now at {unfinished ? "$39.95" : "$17.95"}
            </button>
          </form>
        )}
        <button
          className={styles.viewBtn}
          onClick={() => navigation(`/session/${docId}`)}
        >
          View Photos
        </button>
      </div>
    </div>
  );
}

export default SessionCard;
