import { useSpring, animated } from "react-spring";
import React, { useState } from "react";
import styles from "./index.module.scss";
import SessionCard from "./SessionCard";
import left from "../../assets/images/left.png";
import right from "../../assets/images/right.png";

function Slider({ slides = [], hideControls = false, db }) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [flip, set] = useState(false);
  let prevIndex = activeIndex - 1;
  let nextIndex = activeIndex + 1;
  const handleNextPrev = (factor) => {
    set(true);
    if (factor) {
      setActiveIndex(nextIndex);
    } else {
      setActiveIndex(prevIndex);
    }
    setTimeout(() => {
      set(false);
    }, 500);
  };
  // if (activeIndex === 0) {
  //   prevIndex = slides.length - 1;
  // }
  // if (activeIndex === slides.length - 1) {
  //   nextIndex = 0;
  // }

  const stylesPrev = useSpring({
    to: { x: 0, opacity: 1 },
    from: { x: -100, opacity: 0 },
    reverse: flip,
  });

  const props = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    reverse: flip,
  });

  const stylesNext = useSpring({
    to: { x: 0, opacity: 1 },
    from: { x: 100, opacity: 0 },
    reverse: flip,
  });

  const prevCard = slides[prevIndex];
  const nextCard = slides[nextIndex];
  const activeCard = slides[activeIndex];
  return (
    <div className={styles.container}>
      {!hideControls && prevCard && (
        <div className={styles.left} onClick={() => handleNextPrev(false)}>
          <img src={left} alt="left" />
        </div>
      )}
      <div className={styles.scroll}>
        <animated.div
          className={`${styles.prevCard} ${styles.card}`}
          style={{
            transform: `translateX(60px) scale(0.9)`,
            visibility: prevCard ? "visible": "hidden", 
            ...stylesPrev,
          }}
        >
          {prevCard && <SessionCard session={prevCard} db={db} />}
        </animated.div>
        <animated.div
          className={`${styles.activeCard} ${styles.card}`}
          style={{
            transform: `translateX(0px) scale(1)`,
            visibility: activeCard ? "visible": "hidden", 
            ...props,
          }}
        >
          {activeCard && <SessionCard session={activeCard} db={db} />}
        </animated.div>
        <animated.div
          className={`${styles.nextCard} ${styles.card}`}
          style={{
            transform: `translateX(-60px) scale(0.9)`,
            visibility: nextCard ? "visible": "hidden", 
            ...stylesNext,
          }}
        >
          {nextCard && <SessionCard session={nextCard} db={db} />}
        </animated.div>
      </div>
      {!hideControls && nextCard && (
        <div className={styles.right} onClick={() => handleNextPrev(true)}>
          <img src={right} alt="left" />
        </div>
      )}
    </div>
  );
}

export default Slider;
