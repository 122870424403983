export const SERVER_URL = "https://santa-apis-dot-santa-95d5e.ts.r.appspot.com";
// export const SERVER_URL = "http://localhost:8080";

export const STATES = {
  QLD: "QLD",
  WA: "WA",
  SA: "SA",
  NSW: "NSW",
  VIC: "VIC",
  TAS: "TAS",
};
export const STATES_DATA = {
  QLD: {
    // BRIS: "Brisbane",
    PACI: "Pacific Fair",
    TOOW: "Toowoomba",
    CARI: "Carindale",
    INDO: "Indooroopilly",
    NORT: "North Lakes",
  },
  WA: {
    PERT: "Perth",
    GARD: "Garden City",
    // KARR: "Karrinyup",
  },
  SA: {
    ADEL: "Adelaide",
    TEAT: "Tea Tree Plaza",
    MARI: "Marion",
  },
  NSW: {
    SYDN: "Sydney",
    ALBU: "Albury",
    DUBB: "Dubbo",
    ERIN: "Erina",
    MIRA: "Miranda",
    PENR: "Penrith",
    CAST: "Castle Hill",
    WAGG: "Wagga",
    SHEL: "Shellharbour",
    MACQ: "Macquarie",
  },
  VIC: {
    MELB: "Melbourne",
    BALL: "Ballarat",
    BEND: "Bendigo",
    CHAD: "Chadstone",
    DONC: "Doncaster",
    EAST: "Eastland",
    // FRAN: "Frankston",
    GEEL: "Geelong",
    HIGH: "Highpoint",
    NORT: "Northland",
    SOUT: "Southland",
    WERR: "Werribee",
    FOUN: "Fountain Gate",
    // TEST: "Test",
  },
  TAS: {
    HOBA: "Hobart",
    LAUN: "Launceston",
  },
};

export const SANTA_TITLE = "Santa Prints";
export const MAGIC_TITLE = "Magic Prints";
export const GREENSCREEN_TITLE = "Green Screen Prints";
export const CARD_TITLES = {
  santa1: SANTA_TITLE,
  santa2: SANTA_TITLE,
  santa3: SANTA_TITLE,
  magic1: MAGIC_TITLE,
  magic2: MAGIC_TITLE,
  magic1_santa: MAGIC_TITLE,
  magic2_santa: MAGIC_TITLE,
  greenscreen1: GREENSCREEN_TITLE,
  greenscreen2: GREENSCREEN_TITLE,
  greenscreen3: GREENSCREEN_TITLE,
};
export const IMAGE_NAMES = {
  santa1: "Santa 1",
  santa2: "Santa 2",
  santa3: "Santa 3",
  magic1: "Magic 1",
  magic2: "Magic 2",
  magic1_santa: "Plain Magic 1",
  magic2_santa: "Plain Magic 2",
  greenscreen1: "Green Screen 1",
  greenscreen2: "Green Screen 2",
  greenscreen3: "Green Screen 3",
};
