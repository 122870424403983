import React from "react";
import styles from "./index.module.scss";
import OutsideClickHandler from "react-outside-click-handler";

function DropDown({
  values,
  onChange,
  selectedValue,
  placeholder,
  activeField,
  setActiveField,
  setIsOpen,
  isOpen,
}) {
  return (
    <OutsideClickHandler
      disabled={!isOpen}
      onOutsideClick={() => {
        setIsOpen(false);
      }}
    >
      <div className={styles.wrapper}>
        <div
          className={`${styles.dropdown} ${
            isOpen ? styles.open : styles.close
          }`}
          onClick={() => {
            setIsOpen(!isOpen);
            setActiveField(placeholder);
          }}
        >
          {values[selectedValue] || placeholder}
        </div>
        {isOpen && activeField === placeholder && (
          <div className={styles.options}>
            {Object.keys(values).length ? (
              Object.keys(values).map((key, index) => {
                return (
                  <div
                    className={styles.option}
                    key={key}
                    onClick={() => {
                      onChange(key);
                      setIsOpen(false);
                    }}
                  >
                    {values[key]}
                  </div>
                );
              })
            ) : (
              <div className={styles.option}>Please select state</div>
            )}
          </div>
        )}
      </div>
    </OutsideClickHandler>
  );
}

export default DropDown;
