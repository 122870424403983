import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import { collection, query, where, getDocs, orderBy } from "firebase/firestore";
import Header from "../../components/Header";
import Slider from "../../components/Slider";
import { BrowserView, MobileView } from "react-device-detect";
import faqMob from "../../assets/images/mobile/faq.png";
import priceMob from "../../assets/images/mobile/price.png";
import { useNavigate } from "react-router";
import { SERVER_URL } from "../../common/constants";
import OutsideClickHandler from "react-outside-click-handler";
import ReactGA from "react-ga4";
import RequestPhotos from "../../components/RequestPhotos";

export function MobHeader({
  title,
  solidBG = false,
  showBack = false,
  backRoute = "/sessions",
}) {
  const [showPriceList, setShowPriceList] = useState(false);
  const navigation = useNavigate();
  return (
    <div id="header">
      <div className={styles.header}>
        <div
          onClick={() => navigation(backRoute)}
          className={styles.btnWrapper}
          style={{
            visibility: showBack ? "visible" : "hidden",
          }}
        >
          <button className={styles.backBtn}>Back</button>
        </div>
        <div>{title}</div>
        <div
          className={styles.logout}
          onClick={() => {
            window.localStorage.removeItem("sessionCode");
            navigation("/");
          }}
        >
          Logout
        </div>
      </div>
      <div
        className={styles.actionBtns}
        style={{ background: solidBG ? "#E40A2D" : "transparent" }}
      >
        {/* <div
          className={styles.faq}
          onClick={() => {
            window.open("/FAQs.pdf", "_blank");
          }}
        >
          <img src={faqMob} alt="faq" />
          <div>FAQs</div>
        </div> */}
        <OutsideClickHandler
          disabled={!showPriceList}
          onOutsideClick={() => {
            console.log("outside");
            setShowPriceList(false);
          }}
        >
          <div
            className={styles.price}
            onClick={() => {
              console.log("show price");
              setShowPriceList(!showPriceList);
            }}
          >
            <img src={priceMob} alt="price" />
            <div>Price &amp; Packages</div>

            {showPriceList && (
              <>
                <a
                  href="/Suburban Pricelist -P.pdf"
                  target="_blank"
                  className={styles.priceOptions}
                  style={{
                    background: "#f00",
                    // borderRadius: "5px",
                    // width: "100%",
                    // height: "42px",
                    // position: "absolute",
                    // left: "0px",
                    bottom: "-45px",
                    // display: "flex",
                    // alignItems: "center",
                    // justifyContent: "center",
                    // fontSize: "14px",
                  }}
                  onClick={() => {
                    // window.open("/Suburban Pricelist -P.pdf", "_blank");
                    setShowPriceList(false);
                  }}
                >
                  Suburban Store
                </a>
                <a
                  href="/CBD Pricelist -P.pdf"
                  target="_blank"
                  className={styles.priceOptions}
                  style={{
                    // background: "#000",
                    // borderRadius: "5px",
                    // width: "100%",
                    // height: "42px",
                    // position: "absolute",
                    // left: "0px",
                    bottom: "-90px",
                    // display: "flex",
                    // alignItems: "center",
                    // justifyContent: "center",
                    // fontSize: "14px",
                  }}
                  onClick={() => {
                    window.open("/CBD Pricelist -P.pdf", "_blank");
                    setShowPriceList(false);
                  }}
                >
                  CBD Store
                </a>
              </>
            )}
          </div>
        </OutsideClickHandler>
      </div>
    </div>
  );
}
export function MobLinks() {
  const [showPriceList, setShowPriceList] = useState(false);
  return (
    <div className={styles.actionBtns}>
      <a
        href="/FAQs.pdf"
        target="_blank"
        className={styles.faq}
        // onClick={() => {
        //   window.open("/FAQs.pdf", "_blank");
        // }}
      >
        <img src={faqMob} alt="faq" />
        <div>FAQs</div>
      </a>

      <OutsideClickHandler
        disabled={!showPriceList}
        onOutsideClick={() => {
          console.log("outside");
          setShowPriceList(false);
        }}
      >
        <div
          className={styles.price}
          onClick={() => {
            // console.log("show price");
            setShowPriceList(!showPriceList);
          }}
        >
          <img src={priceMob} alt="price" />
          <div>Price &amp; Packages</div>

          {showPriceList && (
            <>
              <a
                href="/Suburban Pricelist -P.pdf"
                target="_blank"
                style={{
                  background: "#000",
                  borderRadius: "5px",
                  width: "100%",
                  height: "42px",
                  position: "absolute",
                  left: "0px",
                  top: "-90px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "14px",
                  textDecoration: "none",
                  color: "white",
                }}
                onClick={() => {
                  // window.open("/Suburban Pricelist -P.pdf", "_blank");
                  setShowPriceList(false);
                }}
              >
                Suburban Store
              </a>
              <a
                href="/CBD Pricelist -P.pdf"
                target="_blank"
                style={{
                  background: "#000",
                  borderRadius: "5px",
                  width: "100%",
                  height: "42px",
                  position: "absolute",
                  left: "0px",
                  top: "-45px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "14px",
                  textDecoration: "none",
                  color: "white",
                }}
                onClick={() => {
                  // window.open("/CBD Pricelist -P.pdf", "_blank");
                  setShowPriceList(false);
                }}
              >
                CBD Store
              </a>
            </>
          )}
        </div>
      </OutsideClickHandler>
    </div>
  );
}

function Home({ db }) {
  const [sessions, setSessions] = useState([]);
  const userId = window.localStorage.getItem("sessionCode");
  const [hasPartialSessionsOnly, setHasPartialSessionsOnly] = useState(false);

  const navigation = useNavigate();

  // useEffect(
  //   () =>
  //     ReactGA.send({
  //       hitType: "pageview",
  //       page: "home",
  //     }),
  //   []
  // );

  // TODO: useEffect gets called twice. Multiple firestore reads
  useEffect(() => {
    async function fetchData() {
      console.log(userId);
      const data = [];
      const q = query(
        collection(db, "sessions"),
        // where("location", "==", "WA_GARD"),
        // where("userType", "==", "uniqueCode")
        where("userId", "==", userId),
        orderBy("timestamp", "desc")
      );

      console.log("Query executed");
      let isPartial = true;
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        let dataToPush = doc.data();
        if (!dataToPush.partial) {
          dataToPush.docId = doc.id;
          data.push(dataToPush);
          isPartial = false;
        }
      });
      console.log(data);
      if (isPartial) {
        console.log("Has partial sessions only");
        setHasPartialSessionsOnly(true);
      }
      return data;
    }
    fetchData().then((res) => {
      setSessions(res);
    });
  }, [db, userId]);

  return (
    <>
      <div className={styles.homeWrapper}>
        <BrowserView>
          <Header showLogo />
          {hasPartialSessionsOnly ? (
            <RequestPhotos db={db} />
          ) : (
            <Slider slides={sessions} db={db} />
          )}
          {/* <div className={styles.footer}>Brought to you by Rentertainment</div> */}
        </BrowserView>
        <MobileView>
          <MobHeader title="My Sessions" solidBG />
          <div className={styles.mobSessions}>
            {sessions.map((session, key) => {
              const { name, timestamp, sold, userId, docId, unfinished } =
                session;
              return (
                <div key={key} className={styles.mobSessionCard}>
                  <h3>{name}</h3>
                  <h4>{new Date(timestamp.toDate()).toUTCString()}</h4>
                  <div className={styles.btns}>
                    {!sold && (
                      <form
                        action={SERVER_URL + "/create-checkout-session"}
                        method="POST"
                      >
                        <input type="hidden" name="sessionId" value={docId} />
                        <button type="submit" className={styles.buyBtn}>
                          Buy Now for {unfinished ? "$39.95" : "$17.95"}
                        </button>
                      </form>
                    )}
                    <button
                      className={styles.viewBtn}
                      onClick={() => navigation(`/session/${docId}`)}
                    >
                      View Photos
                    </button>
                  </div>
                </div>
              );
            })}
            {hasPartialSessionsOnly && <RequestPhotos db={db} />}
          </div>
        </MobileView>
      </div>
    </>
  );
}

export default Home;
