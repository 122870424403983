import { isEmpty } from "lodash";
import React, { useState, useCallback } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import { getType } from ".";
import ImageViewer from "react-simple-image-viewer";
import styles from "./SessionListing.module.scss";
import download from "../../assets/images/download.png";
import { saveAs } from "file-saver";
import { IMAGE_NAMES } from "../../common/constants";

function SessionListing({ filtered = [], paid = false }) {
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const structured = [];
  filtered.forEach((entry) => {
    if (getType(entry?.type) != null) {
      if (!isEmpty(structured[getType(entry?.type)])) {
        structured[getType(entry?.type)].push(entry);
      } else {
        return (structured[getType(entry?.type)] = [entry]);
      }
    }
  });
  const [activeTab, setActiveTab] = useState("Santa Prints");

  let height = 376;
  if (document) {
    // height =
    //   document.getElementById("header")?.clientHeight +
    //   document.getElementById("listingFooter")?.clientHeight +
    //   110;
    height =
      (paid ? 0 : document.getElementById("listingFooter")?.clientHeight) + 20;
  }

  if (!isEmpty(structured)) {
    return null;
  }

  const showViewer = (imgEle) => {
    let imgIndex = filtered.indexOf(imgEle);
    setCurrentImage(imgIndex);
    setIsViewerOpen(true);
  };

  const closeViewer = () => {
    setIsViewerOpen(false);
  };

  const gotoPrevious = () => {
    let newCurrImg = currentImage - 1;
    if (newCurrImg < 0) newCurrImg += filtered.length;
    setCurrentImage(newCurrImg);
  };

  const gotoNext = () => {
    let newCurrImg = currentImage + 1;
    if (newCurrImg >= filtered.length) newCurrImg -= filtered.length;
    setCurrentImage(newCurrImg);
  };

  const saveImage = (imgEle) => {
    if (paid) {
      saveAs(imgEle.url, imgEle.type + ".jpg");
    }
  };

  return (
    <div className={styles.listing}>
      <MobileView>
        <div className={styles.tabHeader}>
          {Object.keys(structured).map((entry, key) => {
            return (
              <div
                className={`${styles.tabs} ${
                  activeTab === entry && styles.activeTab
                }`}
                key={key}
                onClick={() => setActiveTab(entry)}
              >
                {entry}
              </div>
            );
          })}
        </div>
        <div className={styles.category}>
          <div className={styles.images}>
            {(structured[activeTab] || []).map((ele, key) => {
              const { url, watermarkUrl, type } = ele;
              return (
                <div className={styles.card}>
                  <img
                    alt={type}
                    src={paid ? url : watermarkUrl}
                    className={styles.demoImg}
                    key={key}
                    onClick={() => showViewer(ele)}
                  />
                  <div className={styles.type}>{type}</div>
                  {paid && (
                    <div
                      // className={styles.downloadDiv}
                      style={{
                        position: "absolute",
                        top: "5px",
                        right: "5px",
                      }}
                      onClick={() => saveImage(ele)}
                    >
                      <img
                        src={download}
                        // className={styles.downloadImg}
                        style={{
                          width: "1.5rem",
                          height: "1.5rem",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </MobileView>
      <BrowserView>
        {Object.keys(structured)
          .sort()
          .reverse()
          .map((entry, key) => {
            return (
              <div
                className={styles.category}
                key={key}
                style={{
                  marginBottom: `${height}px`,
                }}
              >
                <div className={styles.entryName}>{entry}</div>
                <div
                  // style={{
                  // maxHeight: `calc(100vh - ${height}px)`,
                  // }}
                  className={styles.images}
                >
                  {structured[entry]
                    .sort((a, b) =>
                      IMAGE_NAMES[a.type].localeCompare(IMAGE_NAMES[b.type])
                    )
                    .map((ele, key) => {
                      const { url, watermarkUrl, type } = ele;
                      return (
                        <div className={styles.card}>
                          <img
                            src={paid ? url : watermarkUrl}
                            className={styles.demoImg}
                            key={key}
                            alt={type}
                            onClick={() => showViewer(ele)}
                          />
                          <div className={styles.type}>{IMAGE_NAMES[type]}</div>
                          {paid && (
                            <div
                              // className={styles.downloadDiv}
                              style={{
                                position: "absolute",
                                top: "5px",
                                right: "5px",
                              }}
                              onClick={() => saveImage(ele)}
                            >
                              <img
                                src={download}
                                // className={styles.downloadImg}
                                style={{
                                  width: "1.5rem",
                                  height: "1.5rem",
                                  cursor: "pointer",
                                }}
                              />
                            </div>
                          )}
                        </div>
                      );
                    })}
                </div>
              </div>
            );
          })}
      </BrowserView>
      {isViewerOpen && (
        <>
          <ImageViewer
            src={filtered.map((img, index) => {
              return paid ? img.url : img.watermarkUrl;
            })}
            currentIndex={currentImage}
            disableScroll={false}
            // isOpen={isViewerOpen}
            // onClickPrev={gotoPrevious}
            // onClickNext={gotoNext}
            closeOnClickOutside={true}
            onClose={closeViewer}
            customControls={[<button>Hello!</button>]}
          />
        </>
      )}
    </div>
  );
}

export default SessionListing;
