import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import {
  collection,
  doc,
  query,
  where,
  getDocs,
  getDoc,
  updateDoc,
} from "firebase/firestore";
import { BrowserView, MobileView } from "react-device-detect";
import Header from "../../components/Header";
import { MobHeader } from "../Home";
import { isEmpty } from "lodash";
import { useParams } from "react-router";
import SessionListing from "./SessionListing";
import { SERVER_URL } from "../../common/constants";
import ReactGA from "react-ga4";

export const getType = (type) => {
  if (["santa1", "santa2", "santa3"].includes(type)) {
    return "Santa Prints";
  } else if (["magic1", "magic2"].includes(type)) {
    return "Magic Prints";
  } else if (["magic1_santa", "magic2_santa"].includes(type)) {
    return "Magic Prints";
  } else if (["greenscreen1", "greenscreen2", "greenscreen3"].includes(type)) {
    return "Green Screen Prints";
  }
  // else if (["jumbo"].includes(type)) {
  //   return "Jumbo";
  // }
  return null;
};

function Session({ session = {}, db }) {
  const [sessionData, setSessionData] = useState(session);
  const { price = 0, sold: paid = false, unfinished = false } = sessionData;
  const [sessionImages, setImages] = useState([]);
  const [isOpenMessageBox, setIsOpenMessageBox] = useState(true);
  const { sessionId } = useParams();
  const userId = window.localStorage.getItem("sessionCode");
  // useEffect(
  //   () =>
  //     ReactGA.send({
  //       hitType: "pageview",
  //       page: "session/",
  //       sessionId,
  //     }),
  //   []
  // );
  useEffect(() => {
    async function getSessionData() {
      // const data = [];
      // const q = query(
      //   collection(db, "sessions"),
      //   where("userId", "==", userId),
      //   limit(1)
      // );
      // const querySnapshot = await getDocs(q);
      // console.log(querySnapshot);
      // querySnapshot.forEach((doc) => {
      //   console.log(doc.data());
      //   data.push(doc.data());
      // });
      // return data[0];
      const docRef = doc(db, "sessions", sessionId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        return docSnap.data();
      } else {
        return null;
      }
    }

    async function getImages({ hasPaid = paid }) {
      const data = [];
      const q = query(
        collection(db, "images"),
        where("sessionId", "==", sessionId)
      );
      const querySnapshot = await getDocs(q);
      let existingImageTypes = [];
      querySnapshot.forEach((doc) => {
        const resData = doc.data();
        if (!hasPaid) {
          delete resData.url;
        }
        // Prevent multiple images of same type from populating
        if (!existingImageTypes.includes(resData.type)) {
          data.push(resData);
          existingImageTypes.push(resData.type);
        }
      });
      return data;
    }

    const showPaymentSuccess = () => {
      global.setModalProps({
        title: "Purchase Successful",
        type: "success",
      });
      global.setOpen(true);
    };

    const showPaymentFail = () => {
      global.setModalProps({
        title: "Purchase Failed",
        type: "error",
      });
      global.setOpen(true);
    };

    async function checkPayment(data) {
      // Check to see if this is a redirect back from Checkout
      const params = new URLSearchParams(window.location.search);
      if (params.get("success")) {
        // TODO: Do this on server
        // const docRef = doc(db, "sessions", sessionId);
        // await updateDoc(docRef, { sold: true });

        if (data.sold) {
          console.log("Sold!");
          showPaymentSuccess();
          return;
        }

        fetch(
          SERVER_URL + "/check-payment",
          // "http://localhost:8080/check-payment",
          {
            method: "post",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
              sessionId: sessionId,
              paymentKey: params.get("pid"),
            }),
          }
        )
          .then((response) => response.text())
          .then((response) => {
            console.log(response);
            if (response === "success") {
              // showPaymentSuccess();
              window.location.reload();
            } else if (response === "fail") {
              showPaymentFail();
            }
          })
          .catch((error) => {
            console.error(error);
            showPaymentFail();
          });
      }

      if (params.get("canceled")) {
        showPaymentFail();
      }
    }

    if (isEmpty(session)) {
      getSessionData().then((data) => {
        checkPayment(data);
        setSessionData(data);
        getImages({ hasPaid: data?.sold }).then((res) => {
          setImages(res);
        });
      });
    } else {
      getImages().then((res) => {
        setImages(res);
      });
    }
  }, [db, paid, userId, sessionId]);

  const filtered = sessionImages.filter((entry) => entry.type !== "video");
  return (
    <>
      <div className={styles.sessionDetail}>
        <BrowserView>
          <Header showLogo logoSize={146} showBack />
          <SessionListing filtered={filtered} paid={paid} />
        </BrowserView>
        <MobileView>
          <MobHeader title="My Packages" solidBG showBack />
          <SessionListing filtered={filtered} paid={paid} />
        </MobileView>
        {!paid && (
          <div className={styles.footer} id="listingFooter">
            *Images without watermark can be downloaded after purchase.
            <form
              action={SERVER_URL + "/create-checkout-session"}
              method="POST"
            >
              <input type="hidden" name="sessionId" value={sessionId} />
              <button
                type="submit"
                className={styles.btn}
                style={{
                  fontSize: paid ? "27px" : "20px",
                }}
                // onClick={handleBuyClick}
              >
                {/*paid ? "Download" : `Buy Now at $${price}`*/}
                Buy Now for {unfinished ? "$39.95" : "$17.95"}
              </button>
            </form>
          </div>
        )}
      </div>
    </>
  );
}

export default Session;
