import React, { useState } from "react";
import { useSpring, animated, config } from "react-spring";
import styles from "./index.module.scss";
import icon from "../../assets/images/brandIcon.png";
import Login from "../Login";
import loader from "../../assets/images/loader.gif";
import { useNavigate } from "react-router";
import { isMobile } from "react-device-detect";
import { useEffect } from "react";
import ReactGA from "react-ga4";

function Landing({ auth }) {
  const [showLogin, setShowLogin] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  // useEffect(
  //   () =>
  //     ReactGA.send({
  //       hitType: "pageview",
  //       page: "landing",
  //     }),
  //   []
  // );
  const propsWrapper = useSpring({ to: { opacity: 1 }, from: { opacity: 0 } });
  const stylesIcon = useSpring({
    from: { y: 0, opacity: 0 },
    to: { y: -100, opacity: 1 },
    delay: 500,
  });
  const stylesButton = useSpring({
    from: { y: -100, opacity: 0 },
    to: { y: 0, opacity: 1 },
    delay: 1000,
  });
  const propsInfo = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    delay: 1200,
  });
  const progressSpringStyleProps = useSpring({
    flex: showLogin ? 2 : 0,
    width: showLogin ? "100%" : 0,
    config: config.molasses,
  });

  const navigate = useNavigate();

  const navigateToLogin = () => {
    const sessionCode = window.localStorage.getItem("sessionCode");
    if (!!sessionCode) {
      setInProgress(true);
      setTimeout(() => {
        setInProgress(false);
        navigate("sessions");
      }, 1000);
    } else {
      setShowLogin(true);
    }
  };

  let showLanding = true;
  if (isMobile) {
    if (showLogin) {
      showLanding = false;
    }
  }

  return (
    <>
      <div className={styles.wrapper}>
        {showLanding && (
          <animated.div style={propsWrapper} className={styles.landingSide}>
            <animated.div style={stylesIcon}>
              <img src={icon} alt="santa" className={styles.brandIcon} />
            </animated.div>
            {!showLogin && (
              <animated.div style={stylesButton}>
                <button className={styles.enterBtn} onClick={navigateToLogin}>
                  Enter
                </button>
              </animated.div>
            )}
            <animated.div className={styles.info} style={propsInfo}>
              Allow 24hrs for photo upload.
              <br />
              Photos deleted every FEB 28.
              <br />
              'ENTER' accepts our{" "}
              <a href="/Privacy Policy - My Santa Photo.pdf" target="_blank">
                Privacy Policy
              </a>
              .
              <br />
              MySantaPhoto.com.au is not a MYER website
            </animated.div>
          </animated.div>
        )}
        <animated.div
          className={styles.loginSide}
          style={progressSpringStyleProps}
        >
          <Login
            showLogin={showLogin}
            setInProgress={setInProgress}
            auth={auth}
          />
        </animated.div>
      </div>
      {inProgress && (
        <div className={styles.loader}>
          <img src={loader} alt="loading" />
        </div>
      )}
    </>
  );
}

export default Landing;
