import React, { useEffect, useState } from "react";
import { SERVER_URL } from "../../common/constants";
import loader from "../../assets/images/loader.gif";
import styles from "./index.module.scss";
import { collection, getDocs, query, where } from "firebase/firestore";

const RequestPhotos = ({ db }) => {
  const [hasRequestedPhotos, setHasRequestedPhotos] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const userId = window.localStorage.getItem("sessionCode");

  // useEffect(() => {
  //   const requestedPhotos = localStorage.getItem("requestedPhotos");
  //   if (requestedPhotos) {
  //     console.log("requestedPhotos: " + requestedPhotos);
  //     setHasRequestedPhotos(true);
  //   }
  // }, []);

  // Check if request has already been submitted or not
  useEffect(() => {
    async function fetchData() {
      const q = query(
        collection(db, "partial-requests"),
        where("userId", "==", userId)
      );

      const querySnapshot = await getDocs(q);
      if (querySnapshot.empty) {
        setHasRequestedPhotos(false);
      } else {
        setHasRequestedPhotos(true);
      }

      setIsLoading(false);
    }

    fetchData();
  }, [db, userId]);

  const requestPhotos = async () => {
    setIsLoading(true);

    const requestRes = await fetch(
      SERVER_URL + `/api/user/${userId}/request-photos`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        // body: JSON.stringify({ userId }),
      }
    );

    if (requestRes.ok) {
      const data = await requestRes.json();
      if (data.id) {
        setHasRequestedPhotos(true);
        // localStorage.setItem("requestedPhotos", data.id);
        showRequestPhotosSuccess();
        setIsLoading(false);
        return;
      }
    }

    setIsLoading(false);
    showRequestPhotosFail();
  };

  const showRequestPhotosSuccess = () => {
    global.setModalProps({
      title: "Photos Requested Successfully",
      type: "success",
    });
    global.setOpen(true);

    setTimeout(() => {
      global.setOpen(false);
    }, 5000);
  };

  const showRequestPhotosFail = () => {
    global.setModalProps({
      title: "Photos Request Failed",
      type: "error",
    });
    global.setOpen(true);

    setTimeout(() => {
      global.setOpen(false);
    }, 5000);
  };

  return (
    <>
      {isLoading && (
        <div className={styles.loader}>
          <img src={loader} alt="loading" />
        </div>
      )}
      <div className={styles.sessions}>
        <div className={styles.sessionCard}>
          {!hasRequestedPhotos ? (
            <>
              <h3 style={{ marginBottom: "1rem" }}>Photos Not Found</h3>
              <h4>
                If you have collected photo prints or paid on site, please allow
                24 hours for the photos to be uploaded.
                <br />
                <br />
                If you could not finish your session on-site, you can request
                your photos with the "REQUEST PHOTOS" button below.
              </h4>

              <div className={styles.actionBtns}>
                <button
                  className={styles.viewBtn}
                  onClick={requestPhotos}
                  disabled={isLoading || hasRequestedPhotos}
                >
                  {hasRequestedPhotos ? "Photos Requested" : "Request Photos"}
                </button>
              </div>
            </>
          ) : (
            <>
              <h3 style={{ marginBottom: "1rem" }}>Photos Requested</h3>
              <h4>
                Request for photos from an incomplete session has been sent.
                <br />
                Your photos will be available online in 24 hours.
              </h4>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default RequestPhotos;
