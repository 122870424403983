import React from "react";
import styles from "./index.module.scss";
import faq from "../../assets/images/mobile/faq.png";
import price from "../../assets/images/mobile/price.png";
import logout from "../../assets/images/logout.png";
import { useSpring, animated } from "react-spring";
import icon from "../../assets/images/brandIcon.png";
import { useNavigate } from "react-router";
import { BrowserView, MobileView } from "react-device-detect";
import { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import ReactGA from "react-ga4";

function Header({
  showBack = false,
  showLogo = false,
  logoSize = 300,
  backRoute = "/sessions",
}) {
  const [showPriceList, setShowPriceList] = useState(false);
  const isLoggedIn = window.localStorage.getItem("sessionCode");
  const stylesIcon = useSpring({
    from: { y: -100, opacity: 0 },
    to: { y: 0, opacity: 1 },
    delay: 500,
  });
  const navigation = useNavigate();
  return (
    <header id="header">
      <div
        onClick={() => navigation(backRoute)}
        className={styles.btnWrapper}
        style={{
          visibility: showBack ? "visible" : "hidden",
        }}
      >
        <button className={styles.backBtn}>Back</button>
      </div>
      {showLogo && (
        <animated.div style={stylesIcon} className={styles.logoWrapper}>
          <img
            src={icon}
            alt="santa"
            className={styles.brandIcon}
            style={{ width: `${logoSize}px` }}
          />
        </animated.div>
      )}
      <div className={styles.navLinks}>
        <div className={styles.links}>
          {/* <a href="/FAQs.pdf" target="_blank" className={styles.faq}>
            <img src={faq} alt="info" />
            <span>FAQs</span>
          </a> */}
          <OutsideClickHandler
            disabled={!showPriceList}
            onOutsideClick={() => {
              setShowPriceList(false);
            }}
          >
            <div
              onClick={() => {
                setShowPriceList(!showPriceList);
              }}
              className={styles.price}
            >
              <img src={price} alt="info" />
              <span>Price &amp; Packages</span>

              {showPriceList && (
                <>
                  <a
                    href="/Suburban Pricelist -L.pdf"
                    target="_blank"
                    style={{
                      //   background: "#000",
                      //   borderRadius: "5px",
                      //   width: "100%",
                      //   height: "42px",
                      //   position: "absolute",
                      //   left: "0px",
                      bottom: "-45px",
                      //   display: "flex",
                      //   alignItems: "center",
                      //   justifyContent: "center",
                      //   fontSize: "14px",
                      //   textDecoration: "none",
                      //   color: "white",
                    }}
                    className={styles.priceOptions}
                    onClick={() => {
                      // window.open("/Suburban Pricelist -L.pdf", "_blank");
                      setShowPriceList(false);
                    }}
                  >
                    Suburban Store
                  </a>
                  <a
                    href="/CBD Pricelist -L.pdf"
                    target="_blank"
                    style={{
                      // background: "#000",
                      // borderRadius: "5px",
                      // width: "100%",
                      // height: "42px",
                      // position: "absolute",
                      // left: "0px",
                      bottom: "-90px",
                      // display: "flex",
                      // alignItems: "center",
                      // justifyContent: "center",
                      // fontSize: "14px",
                      // textDecoration: "none",
                      // color: "white",
                    }}
                    className={styles.priceOptions}
                    onClick={() => {
                      // window.open("/CBD Pricelist -L.pdf", "_blank");
                      setShowPriceList(false);
                    }}
                  >
                    CBD Store
                  </a>
                </>
              )}
            </div>
          </OutsideClickHandler>
          {!!isLoggedIn && (
            <div
              className={styles.logout}
              onClick={() => {
                let webCode = window.localStorage.getItem("sessionCode");
                let webCodeSplit = webCode.split("_");
                ReactGA.event("logout", {
                  state: webCodeSplit[0] || "",
                  store: webCodeSplit[1] || "",
                  code: webCodeSplit[2] || "",
                  webCode: window.localStorage.getItem("sessionCode"),
                });
                window.localStorage.removeItem("sessionCode");
                navigation("/");
              }}
            >
              <img src={logout} alt="info" />
              <span>Logout</span>
            </div>
          )}
        </div>
      </div>
    </header>
  );
}

export default Header;
