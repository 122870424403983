import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from "react-router-dom";
import Home from "./scenes/Home";
import Landing from "./scenes/Landing";
import Login from "./scenes/Login";
import ReactGA from "react-ga4";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore";
import { getAuth } from "firebase/auth";
import Session from "./scenes/Session";
import Popup from "reactjs-popup";
import { useState } from "react";
import success from "./assets/images/success.gif";
import error from "./assets/images/error.gif";
import { isMobile } from "react-device-detect";
import { useEffect } from "react";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyALVp3dS4JR746VDFuSCceMnTNd-VEWtAw",
  authDomain: "santa-95d5e.firebaseapp.com",
  projectId: "santa-95d5e",
  storageBucket: "santa-95d5e.appspot.com",
  messagingSenderId: "437750990819",
  appId: "1:437750990819:web:d28d103f0dd3b5a8a742d6",
  measurementId: "G-MVL13ZFW9B",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);

function App() {
  const [open, setOpen] = useState(false);
  const [modalProps, setModalProps] = useState({
    type: "success",
    title: "Santa App",
  });

  useEffect(() => {
    const setGA = () => {
      const isProduction = process.env.NODE_ENV === "production";
      ReactGA.initialize("G-3JW1QMC11H");
      // ReactGA.initialize("G-3JW1QMC11H", { testMode: !isProduction });

      // ReactGA.send("pageview");
      // // Send pageview with a custom path
      // ReactGA.send({ hitType: "pageview", page: "/my-path" });
    };

    setGA();
  }, []);

  const closeModal = () => {
    setOpen(false);
  };

  global.setOpen = setOpen;
  global.closeModal = closeModal;
  global.setModalProps = setModalProps;
  const { type, title } = modalProps;
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/">
        <Route index element={<Landing auth={auth} />} />
        <Route path="sessions" element={<Home db={db} />} />
        <Route path="login" element={<Login auth={auth} />} />
        <Route path="session/:sessionId" element={<Session db={db} />} />
      </Route>
    )
  );
  return (
    <>
      <RouterProvider router={router} />
      <Popup open={open} closeOnDocumentClick onClose={closeModal}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            borderRadius: "10px",
            padding: "2rem",
            textAlign: "center",
            // background: type === "success" ? "#DAFFE2" : "#FFCDCD",
          }}
        >
          <div style={{ fontSize: isMobile ? "1.25rem" : "2rem" }}>{title}</div>
          <img
            src={type === "success" ? success : error}
            alt={type}
            style={{
              height: isMobile ? "100px" : "150px",
              objectFit: "contain",
            }}
          />
        </div>
      </Popup>
    </>
  );
}

export default App;
